import { motion } from "framer-motion";
import aboutFull from "../assets/about1.JPG";
import aboutLeft from "../assets/about2.JPG";
import aboutRight from "../assets/about3.JPG";
import Button from "../components/Button";
export default function About(props) {
  return (
    <div className="py-4 sm:py-10 md:py-16 text-center text-lg sm:text-xl md:text-2xl" id="about">
      <div className="max-w-[calc(100%-40px)] lg:max-w-[1160px] mx-5 lg:mx-auto flex items-center justify-center max-md:flex-wrap relative">
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ ease: "easeOut", duration: 1.2, delay: 0.5 }}
          className="lg:basis-1/2 mr-5 about-left max-[410px]:hidden"
        >
          <img
            className="mb-5 ml-auto w-[350px] lg:w-[450px] xl:w-[520px] max-h-[350px] lg:max-h-[450px] xl:max-h-[520px] object-cover h-auto"
            src={aboutFull}
            alt="about"
            width={520}
            height={520}
          />
          <div className="flex justify-end">
            <img
              className="mr-5 w-[162px] lg:w-[215px] xl:w-[250px] max-h-[162px] lg:max-h-[215px] xl:max-h-[250px] object-cover h-auto" alt="about"
              src={aboutLeft}
            />
            <img className="w-[162px] lg:w-[215px] xl:w-[250px] max-h-[162px] lg:max-h-[215px] xl:max-h-[250px] object-cover h-auto" src={aboutRight} alt="about" width={250} height={250} />
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ ease: "easeOut", duration: 1, delay: 1.1 }}
          className="lg:basis-1/2 text-left bg-[#333333] max-md:my-4 w-full md:w-[calc(100%-355px)] lg:w-[450px] xl:w-[520px] h-full md:h-[525px] lg:h-[685px] xl:h-[790px] py-6 lg:py-11 px-6 lg:px-10 xl:px-12 about-right"
        >
          <h2 className="text-2xl lg:text-3xl font-medium uppercase mb-4 lg:mb-8">О НАС</h2>
          <p className="text-lg font-[Raleway] leading-6 lg:leading-8 mb-3 lg:mb-5">
            Vasat объединяет страсть к текстилю и мастерство, чтобы предложить
            не просто трикотаж, а произведения искусства. Мы гарантируем
            продукцию, вдохновляющую на создание уникальных и качественных
            изделий, контролируя процесс с момента выбора пряжи до создания
            идеального трикотажного изделия.
          </p>
          <p className="leading-6 lg:leading-8 text-lg font-[Raleway] mb-6 lg:mb-16">
            Следите за нами{" "}
            <a className="underline decoration-1 underline-offset-4" href="/">
              в социальных сетях
            </a>{" "}
            ,чтобы узнавать о последних новинках и тенденциях первыми.
          </p>
          <a href="#form" onClick={()=>{props.setIsShow(true)}} >
            <Button/>
            </a>
        </motion.div>
      </div>
    </div>
  );
}
