import React from "react";
import { useForm, ValidationError } from "@formspree/react";
export default function Form(props) {
  const [state, handleSubmit, reset] = useForm("xzbnajzw");
  if (state.succeeded) {
    props.setIsShow(false);
    alert('Сообщение успешно отправлено!');
  }
  return (
    <div className="relative">
      <form className="w-full h-auto mx-5 sm:mx-auto space-y-6 mt-28 mb-6" onSubmit={handleSubmit} onReset={reset}>
        <div className="w-[80%] sm:w-[60%] md:w-[40%] mx-auto flex items-center justify-center md:space-x-4 max-md:flex-wrap">
          <input
            type="text"
            id="name"
            name="Name"
            placeholder="Your Name"
            required
            className="w-full px-4 py-1 bg-transparent border-b-2 border-b-[#c1a76e] focus:outline-none focus:ring-0"
          />
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your Email"
            required
            className="w-full px-4 py-1 bg-transparent border-b-2 border-b-[#c1a76e] focus:outline-none focus:ring-0"
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>
        <input
          type="text"
          id="subject"
          name="subject"
          placeholder="Subject"
          required
          className="w-[80%] sm:w-[60%] md:w-[40%] mx-auto px-4 py-1 bg-transparent border-b-2 border-b-[#c1a76e] focus:outline-none focus:ring-0 block"
        />
        <textarea
          id="message"
          name="message"
          placeholder="Message"
          required
          rows={10}
          className="w-[80%] sm:w-[60%] md:w-[40%] mx-auto px-4 py-1 bg-transparent border-b-2 border-b-[#c1a76e] focus:outline-none focus:ring-0 block"
        />
         <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      <div className="w-[80%] sm:w-[60%] md:w-[40%] mx-auto flex items-center justify-center flex-wrap  md:no-wrap gap-6 md:gap-x-28">
        <button className="block px-6 sm:px-24 py-1 sm:py-1.5 whitespace-nowrap text-xs sm:text-sm leading-6 sm:leading-8 bg-[#c1a76e] uppercase text-black font-semibold rounded-[20px]" onClick={()=>{props.setIsShow(false)}} >Отмена</button>
        <button
          type="submit"
          disabled={state.submitting}
          className="block px-6 sm:px-10 py-1 sm:py-1.5 whitespace-nowrap text-xs sm:text-sm leading-6 sm:leading-8 bg-[#c1a76e] uppercase text-black font-semibold rounded-[20px]"
        >
          Отправить сообщение
        </button>
      </div>
      </form>
    </div>
  );
}
