import { motion } from "framer-motion";
export default function OurAdvantages() {
  return (
    <div className="adv-bg relative" id="ourAdvantages">
      <div>
      <div className="h-full max-lg:mx-5 py-4 sm:py-10 md:py-14 lg:py-16 xl:py-24 text-center">
        <motion.h3
          initial={{ opacity: 0, x: 100, y: -50 }}
          whileInView={{ opacity: 1, x: 0, y: 0 }}
          transition={{ ease: "easeOut", duration: 1.5, delay: 0.5 }}
          className="w-full mx-5 text-2xl sm:text-3xl md:text-4xl xl:text-[42px] font-bold leading-7 md:leading-10 mb-5"
        >
          Наши Преимущества
        </motion.h3>
        <div className="flex justify-center max-[550px]:flex-wrap text-left gap-x-0 md:gap-x-12 xl:gap-x-16 p-0 sm:p-4 xl:p-8">
          <div className="adv-left flex flex-col max-[550px]:w-full max-[550px]:text-center max-md:justify-between gap-6 xl:gap-10">
            <motion.div
              initial={{ opacity: 0, x: -100, y: -20 }}
              whileInView={{ opacity: 1, x: 0, y: 0 }}
              transition={{ ease: "easeOut", duration: 1.5, delay: 0.5 }}
              className="content w-full min-[550px]:max-w-[300px] lg:max-w-[350px] md:relative my-4 md:my-14 xl:my-20 mx-4 md:mx-10 xl:mx-16"
            >
              {/* <div className="absolute max-md:hidden -top-16 lg:-top-20 -left-16  lg:-left-20 xl:-left-28 z-20 text-[100px] lg:text-[160px] xl:text-[200px] text-[#8a8a8a] font-bold leading-[146px] opacity-30">
                01
              </div> */}
              <h5 className="text-xl sm:text-2xl lg:text-[31px] font-semibold leading-7 md:leading-10">
                Полный цикл производства
              </h5>
              <p className="text-lg lg:text-xl font-medium leading-5 md:leading-6 lg:leading-7 font-[Relawey] text-[#7c6c4c]">
                Полный контроль на каждом этапе, от спиннинга пряжи до окраски
                трикотажного изделия, гарантирует исключительное качество.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -100, y: -20 }}
              whileInView={{ opacity: 1, x: 0, y: 0 }}
              transition={{ ease: "easeOut", duration: 1.5, delay: 0.7 }}
              className="content w-full min-[550px]:max-w-[300px] lg:max-w-[350px] md:relative my-4 md:my-14 xl:my-20 mx-4 md:mx-10 xl:mx-16"
            >
              {/* <div className="absolute max-md:hidden -top-16 lg:-top-20 -left-16  lg:-left-20 xl:-left-28 z-20 text-[100px] lg:text-[160px] xl:text-[200px] text-[#8a8a8a] font-bold leading-[146px] opacity-30">
                03
              </div> */}
              <h5 className="text-xl sm:text-2xl lg:text-[31px] font-semibold leading-7 md:leading-10">
                Уникальность
              </h5>
              <p className="text-lg lg:text-xl font-medium leading-5 md:leading-6 lg:leading-7 font-[Relawey] text-[#7c6c4c]">
                Каждая партия трикотажа — результат инноваций и творчества наших
                мастеров.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -100, y: -20 }}
              whileInView={{ opacity: 1, x: 0, y: 0 }}
              transition={{ ease: "easeOut", duration: 1.5, delay: 0.7 }}
              className="content w-full min-[550px]:max-w-[300px] lg:max-w-[350px] md:relative my-4 md:my-14 xl:my-20 mx-4 md:mx-10 xl:mx-16"
            >
              {/* <div className="absolute max-md:hidden -top-16 lg:-top-20 -left-16  lg:-left-20 xl:-left-28 z-20 text-[100px] lg:text-[160px] xl:text-[200px] text-[#8a8a8a] font-bold leading-[146px] opacity-30">
                05
              </div> */}
              <h5 className="text-xl sm:text-2xl lg:text-[31px] font-semibold leading-7 md:leading-10">
                Индивидуальный подход
              </h5>
              <p className="text-lg lg:text-xl font-medium leading-5 md:leading-6 lg:leading-7 font-[Relawey] text-[#7c6c4c]">
                Разработка эксклюзивных трикотажных изделий по вашему заказу.
              </p>
            </motion.div>
          </div>
          <div className="adv-right flex flex-col max-[550px]:w-full max-[550px]:text-center max-md:justify-between gap-6 xl:gap-10 mt-0 md:mt-32 xl:mt-40">
            <motion.div
              initial={{ opacity: 0, x: 100, y: -20 }}
              whileInView={{ opacity: 1, x: 0, y: 0 }}
              transition={{ ease: "easeOut", duration: 1.5, delay: 0.6 }}
              className="content w-full min-[550px]:max-w-[300px] lg:max-w-[350px] md:relative my-4 md:my-14 xl:my-20 mx-4 md:mx-10 xl:mx-16"
            >
              {/* <div className="absolute max-md:hidden -top-16 lg:-top-20 -left-16  lg:-left-20 xl:-left-28 z-20 text-[100px] lg:text-[160px] xl:text-[200px] text-[#8a8a8a] font-bold leading-[146px] opacity-30">
                02
              </div> */}
              <h5 className="text-xl sm:text-2xl lg:text-[31px] font-semibold leading-7 md:leading-10">
                Прямое сотрудничество
              </h5>
              <p className="text-lg lg:text-xl font-medium leading-5 md:leading-6 lg:leading-7 font-[Relawey] text-[#7c6c4c]">
                Работаем без посредников, что обеспечивает конкурентоспособные
                цены.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 100, y: -20 }}
              whileInView={{ opacity: 1, x: 0, y: 0 }}
              transition={{ ease: "easeOut", duration: 1.5, delay: 0.8 }}
              className="content w-full min-[550px]:max-w-[300px] lg:max-w-[350px] md:relative my-4 md:my-14 xl:my-20 mx-4 md:mx-10 xl:mx-16"
            >
              {/* <div className="absolute max-md:hidden -top-16 lg:-top-20 -left-16  lg:-left-20 xl:-left-28 z-20 text-[100px] lg:text-[160px] xl:text-[200px] text-[#8a8a8a] font-bold leading-[146px] opacity-30">
                04
              </div> */}
              <h5 className="text-xl sm:text-2xl lg:text-[31px] font-semibold leading-7 md:leading-10">
                Гарантия качества
              </h5>
              <p className="text-lg lg:text-xl font-medium leading-5 md:leading-6 lg:leading-7 font-[Relawey] text-[#7c6c4c]">
                Мы стоим за каждым изделием нашего трикотажа.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 100, y: -20 }}
              whileInView={{ opacity: 1, x: 0, y: 0 }}
              transition={{ ease: "easeOut", duration: 1.5, delay: 0.7 }}
              className="content w-full min-[550px]:max-w-[300px] lg:max-w-[350px] md:relative my-4 md:my-14 xl:my-20 mx-4 md:mx-10 xl:mx-16"
            >
              {/* <div className="absolute max-md:hidden -top-16 lg:-top-20 -left-16  lg:-left-20 xl:-left-28 z-20 text-[100px] lg:text-[160px] xl:text-[200px] text-[#8a8a8a] font-bold leading-[146px] opacity-30">
                06
              </div> */}
              <h5 className="text-xl sm:text-2xl lg:text-[31px] font-semibold leading-7 md:leading-10">
                Доставка по СНГ
              </h5>
              <p className="text-lg lg:text-xl font-medium leading-5 md:leading-6 lg:leading-7 font-[Relawey] text-[#7c6c4c]">
                Ваш заказ будет доставлен точно в срок, в любую точку
                Содружества Независимых Государств.
              </p>
            </motion.div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}
