import { motion } from "framer-motion";
export default function OurWork(props) {
  return (
    <div className="w-auto h-full py-10 xl:py-14 text-center our-work max-sm:mx-5" id="ourWork">
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeOut", duration: 1.5, delay: 0.4 }}
      >
        <h2 className="text-2xl sm:text-4xl xl:text-[42px] font-bold leading-10 mb-3 xl:mb-5 our-work-heading">
          Как мы работаем
        </h2>
        <p className="text-base sm:text-lg xl:text-xl font-[Raleway] leading-7 mb-10 our-work-text">
          Vasat обеспечивает персонализированный подход к каждому клиенту
        </p>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: -200, scale: 0.8 }}
        whileInView={{ opacity: 1, x: 0, scale: 1 }}
        transition={{ ease: "easeOut", duration: 1.5, delay: 0.5 }}
        className="grid grid-cols-1 min-[500px]:grid-cols-4 gap-y-8 mx-auto w-auto xl:max-w-[1160px] our-work-content"
      >
        <div className="flex items-center max-[500px]:hidden">
          <div className="w-14 sm:w-[90px] h-14 sm:h-[90px] rounded-full bg-[#c1a76e] text-center text-black text-4xl sm:text-[55px] font-semibold">
            1
          </div>
          <div className="w-[calc(100%-56px)] sm:w-[calc(100%-90px)] h-0.5 bg-[#c1a76e]"></div>
        </div>
        <div className="flex items-center max-[500px]:hidden">
          <div className="w-14 sm:w-[90px] h-14 sm:h-[90px] rounded-full bg-[#c1a76e] text-center text-black text-4xl sm:text-[55px] font-semibold">
            2
          </div>
          <div className="w-[calc(100%-56px)] sm:w-[calc(100%-90px)] h-0.5 bg-[#c1a76e]"></div>
        </div>
        <div className="flex items-center max-[500px]:hidden">
          <div className="w-14 sm:w-[90px] h-14 sm:h-[90px] rounded-full bg-[#c1a76e] text-center text-black text-4xl sm:text-[55px] font-semibold">
            3
          </div>
          <div className="w-[calc(100%-56px)] sm:w-[calc(100%-90px)] h-0.5 bg-[#c1a76e]"></div>
        </div>
        <div className="flex items-center max-[500px]:hidden">
          <div className="w-14 sm:w-[90px] h-14 sm:h-[90px] rounded-full bg-[#c1a76e] text-center text-black text-4xl sm:text-[55px] font-semibold">
            4
          </div>
        </div>
        <div className="text-left pr-2 md:pr-4">
          <h5 className="text-base sm:text-xl md:text-2xll lg:text-[35px] font-bold mb-1.5 md:mb-2.5 lg:mb-4">Личный менеджер</h5>
          <p className="text-xs sm:text-sm lg:text-[15px] leading-4 sm:leading-5 lg:leading-6 font-[Raleway]">
            Он сопровождает ваш заказ от начала до конца, обеспечивая
            своевременное общение и высокий уровень сервиса
          </p>
        </div>
        <div className="text-left pr-2 md:pr-4">
          <h5 className="text-base sm:text-xl md:text-2xll lg:text-[35px] font-bold mb-1.5 md:mb-2.5 lg:mb-4">Без посредников</h5>
          <p className="text-xs sm:text-sm lg:text-[15px] leading-4 sm:leading-5 lg:leading-6 font-[Raleway]">
            Напрямую сотрудничая с нами, вы получаете доступ к конкурентным
            ценам и гарантированному качеству
          </p>
        </div>
        <div className="text-left pr-2 md:pr-4">
          <h5 className="text-base sm:text-xl md:text-2xll lg:text-[35px] font-bold mb-1.5 md:mb-2.5 lg:mb-4">Гарантия качества</h5>
          <p className="text-xs sm:text-sm lg:text-[15px] leading-4 sm:leading-5 lg:leading-6 font-[Raleway]">
            Мы гордимся каждым продуктом, который производим, и предлагаем
            гарантию на всю нашу продукцию
          </p>
        </div>
        <div className="text-left">
          <h5 className="text-base sm:text-xl md:text-2xll lg:text-[35px] font-bold mb-1.5 md:mb-2.5 lg:mb-4">Доставка по СНГ</h5>
          <p className="text-xs sm:text-sm lg:text-[15px] leading-4 sm:leading-5 lg:leading-6 font-[Raleway]">
            Мы понимаем важность сроков и предлагаем индивидуальные решения по
            доставке для каждого заказа, гарантируя, что ваша продукция будет
            доставлена вовремя и в идеальном состоянии
          </p>
        </div>
      </motion.div>
      <motion.button
        initial={{ opacity: 0, x: 60, y: 60, scale: 0.8 }}
        whileInView={{ opacity: 1, x: 0, y: 0, scale: 1 }}
        transition={{ ease: "easeOut", duration: 1.5, delay: 0.6 }}
        id="form"
        onClick={()=>{props.setIsShow(true)}} 
        className="block mt-6 sm:mt-10 mx-auto px-6 sm:px-8 xl:px-[49px] py-1.5 sm:py-2 xl:py-[14px] text-sm sm:text-lg xl:text-[22px] leading-5 sm:leading-7 xl:leading-8 bg-[#c1a76e] uppercase text-black font-semibold rounded-[30px] our-work-button"
      >
        <a href="#form"  onClick={()=>{props.setIsShow(true)}} 
        >
        Оставить заявку
        </a>
      </motion.button>
    </div>
  );
}
