import { motion } from "framer-motion";
import React, { useEffect  } from "react";
import point from "../assets/point.svg";
import Map  from 'ol/Map.js';
import View from 'ol/View.js';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Icon,Style } from 'ol/style';
export default function Contact() {
  useEffect(() => {
    const map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center:fromLonLat([parseFloat(69.192829), parseFloat(41.236312)]),
        // center: [41.236312, 69.192829],
        zoom: 14,
      }),
    });
    const marker = new Feature({
      geometry: new Point(fromLonLat([69.192829, 41.236312])),
    });

    const vectorSource = new VectorSource({
      features: [marker],
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: new Style({
        image: new Icon({
          src: point,
          color: 'rgba(255, 0, 0, .5)',
        }),
      }),
    });

    map.addLayer(vectorLayer);

    return () => {
      map.setTarget(null);
    };
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0, y: 120 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ ease: "easeOut", duration: 1.5, delay: 0.8 }}
      className="max-w-[1160px] w-auto h-auto text-center py-10 xl:py-16 mx-5 xl:mx-auto"
      id="contact"
    >
      <h2 className="text-2xl sm:text-3xl lg:text-[42px] font-semibold mb-6 lg:mb-10">
        Наши контакты
      </h2>
      <p className="text-lg sm:text-xl lg:text-2xl leading-6 lg:leading-8 mb-4">
        Готовы начать сотрудничество или хотите узнать больше о наших услугах?
        Свяжитесь с нами через форму на сайте, по электронной почте{" "}
        <a
          className="underline underline-offset-4 decoration-1"
          href="mailto:vasattextile@gmail.com"
        >
          vasattextile@gmail.com
        </a>{" "}
        или в социальных сетях. Мы рады обсудить ваш проект и предложить лучшие
        решения для его реализации. Присоединяйтесь к нашему сообществу в{" "}
        <a
          className="underline underline-offset-4 decoration-1"
          href="mailto:vasattextile@gmail.com"
        >
          социальных сетях
        </a>
        , чтобы всегда быть на связи с последними новостями и предложениями от
        Vasat.
      </p>
      <div className="flex mt-14 max-lg:flex-wrap">
        <div className="w-full lg:w-1/2">
          <div className="relative w-[550px] h-[360px] map-wrapper mx-auto">
            <div className="absolute top-0 left-0 right-0 bg-black/75 z-30 map-address text-lg">г.Ташкент, Янгихаятский район, Собрание граждан махалли
            Чаштепа, тупик 13 Чаштепа, д. 32а</div>
            <div  id="map" style={{ width: '550px', height: '360px' }}></div>
          </div>
          {/* <img className="mx-auto rounded border-4 border-[#c1a76e]" src={map} alt="map" width={550} /> */}
        </div>
        <div className="w-full lg:w-1/2 flex flex-col items-center px-4 py-10 gap-y-6 font-medium text-lg sm:text-xl lg:text-2xl leading-6 lg:leading-8">
          <div>
            Адрес: г.Ташкент, Янгихаятский район, Собрание граждан махалли
            Чаштепа, тупик 13 Чаштепа, д. 32а
          </div>
          <div className="flex lg:flex-col">
            Телефон:
            <a className="block hover:scale-105 mx-2" href="tel:+998555000710">
              +998 55 500 0710
            </a>
            <a className="block hover:scale-105 mx-2" href="tel:+998940057100">
              +998 94 005 7100
            </a>
            <a className="block hover:scale-105 mx-2" href="tel:+998950557100">
              +998 95 055 7100
            </a>
          </div>
          <div>
          <div className="flex lg:flex-col">
          Почта:
          <a className="block hover:scale-105 mx-2" href="mailto:vasattextile@gmail.com">
            vasattextile@gmail.com
          </a>
          </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
