import { motion } from "framer-motion";
import logo from "../assets/vasat-logo.png";
import Button from "../components/Button";
export default function Hero(props) {
  return (
    <div className="hero-bg relative">
      <div className="w-full h-full py-8 sm:py-12 md:py-24">
        <div className="site-header">
          <motion.div  initial={{ opacity: 0, y: -80 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ ease: "easeOut", duration: 1.2, delay: 0.5 }}>
          <img className="mx-auto mb-8 sm:mb-10 md:mb-20 max-sm:scale-75 max-md:scale-90" src={logo} alt="Vasat" width={200} />
          </motion.div>
          <motion.div  initial={{ opacity: 0, y: 200, scaleX: 0.9 }}
          whileInView={{ opacity: 1, y: 0, scaleX: 1 }}
          transition={{ ease: "easeOut", duration: 1.2, delay: 0.6 }}>
          <h1 className="max-sm:mx-5 mb-10 md:mb-16 text-center text-2xl md:text-4xl font-bold uppercase">
            Vasat — Искусство текстиля от пряжи до трикотажного изделия
          </h1>
          <p className="mx-5 sm:mx-auto text-base sm:text-lg md:text-xl leading-7 md:leading-8 text-center uppercase font-[Raleway] mb-0">
            Мы превращаем вдохновение в высококачественный трикотаж, окрашенный
            с заботой и вниманием к деталям.
          </p>
          <p className="mx-5 sm:mx-auto leading-6 md:leading-8 text-sm md:text-base text-center font-[Raleway] mb-10 md:mb-16">
            Подписывайтесь на нас в{" "}
            <a className="underline decoration-1 underline-offset-4" href="/">
              социальных сетях
            </a>
            , чтобы не пропустить уникальные предложения!
          </p>
          <a href="#form" onClick={()=>{props.setIsShow(true)}} >
            <Button/>
            </a>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
