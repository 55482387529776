import logo from "../assets/vasat-logo-v.png";
import Button from "../components/Button"

export default function Header(props) {
  return (
    <div className="w-full h-auto fixed top-0 left-0 right-0 z-20 bg-black" id="header">
      <div className="h-[150px] sm:h-[200px] xl:max-w-[1160px] mx-5 xl:mx-auto flex flex-col">
        <div className="flex items-center justify-between py-2.5 sm:py-4">
          <div className="max-sm:hidden sm:w-[150px]"></div>
          <a href="/" className="mx-5 sm:mx-0">
            <img className="" src={logo} alt="Vasat" width={215} />
          </a>
          <div className="flex flex-col">
            <a
              className="block mx-auto py-1.5 sm:py-2.5 text-lg sm:text-xl"
              href="mailto:vasattextile@gmail.com"
            >
              vasattextile@gmail.com
            </a>
            <a href="#form" onClick={()=>{props.setIsShow(true)}} >
            <Button/>
            </a>
          </div>
        </div>
        <div className="border-b border-b-[#c1a76e]"></div>
        <div className="sm:h-full flex items-center justify-center flex-wrap text-sm sm:text-base md:text-xl uppercase menu">
          <a href="#about"  onClick={()=>{props.setIsShow(false)}} className="p-1.5 sm:p-4 hover:animate-pulse whitespace-nowrap">О НАС</a>
          <a href="#contact"  onClick={()=>{props.setIsShow(false)}} className="p-1.5 sm:p-4 hover:animate-pulse whitespace-nowrap">КОНТАКТЫ</a>
          <a href="#form" onClick={()=>{props.setIsShow(true)}} className="p-1.5 sm:p-4 hover:animate-pulse whitespace-nowrap">ОФОРМИТЬ ЗАКАЗ</a>
          <a href="#ourWork"  onClick={()=>{props.setIsShow(false)}} className="p-1.5 sm:p-4 hover:animate-pulse whitespace-nowrap">ДОСТАВКА</a>
        </div>
      </div>
    </div>
  );
}
