import { motion } from "framer-motion";
export default function Footer(props) {
  return (
    <div className="w-auto h-auto">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ ease: "easeOut", duration: 1.2, delay: 0.8 }}
        className="h-auto md:h-[220px] px-10 mx-auto flex max-md:flex-col items-center justify-center md:justify-between"
      >
        <div className="lg:max-w-[250px] text-sm font-thin leading-5 font-[Raleway]">
          Общество с ограниченной
          <br className="max-md:hidden"/> ответственностью{" "}
          <span className="font-extralight">«Vasat»</span>
          <br />
          г.Ташкент, Янгихаятский район, тупик 13 Чаштепа, д.32а
          <br />
          Почта: <a href="mailto:vasattextile@gmail.com">vasattextile@gmail.com</a>
          <br />

        </div>
        <div className="flex items-center justify-center max-sm:flex-wrap text-base sm:text-lg xl:text-xl uppercase menu">
          <a href="#about" onClick={()=>{props.setIsShow(false)}} className="p-2 lg:p-4 hover:animate-pulse">
            О НАС
          </a>
          <a href="#form"  onClick={()=>{props.setIsShow(true)}}  className="p-2 lg:p-4 hover:animate-pulse">
            ОФОРМИТЬ ЗАКАЗ
          </a>
          <a href="#ourWork" onClick={()=>{props.setIsShow(false)}} className="p-2 lg:p-4 hover:animate-pulse">
            ДОСТАВКА
          </a>
        </div>
        <div className="w-auto lg:w-[250px]">
          <a
            href="/"
            className="flex items-center justify-end gap-x-2 text-lg xl:text-xl font-semibold"
            onClick={()=>{props.setIsShow(false)}}
          >
            НАВЕРХ
            <span>
              <svg role="presentation" width="5" height="17" viewBox="0 0 6 20">
                <path
                  fill="#c1a76e"
                  d="M5.78 3.85L3.12.28c-.14-.14-.3-.14-.43 0L.03 3.85c-.14.13-.08.27.13.27h1.72V20h2.06V4.12h1.72c.15 0 .22-.07.19-.17a.26.26 0 00-.07-.1z"
                  fillRule="evenodd"
                ></path>
              </svg>
            </span>
          </a>
        </div>
      </motion.div>
    </div>
  );
}
