import { motion } from "framer-motion";
export default function Button() {
  return (
    <motion.button   whileHover={{
      scale: 1.1,
      transition: { duration: 1 },
    }}
    whileTap={{ scale: 0.9 }}
    className="block mx-auto px-6 sm:px-[30px] py-1 sm:py-1.5 whitespace-nowrap text-xs sm:text-sm leading-6 sm:leading-8 bg-[#c1a76e] uppercase text-black font-semibold rounded-[20px]">
    Оставить заявку
  </motion.button>
  );
}