import adv2 from '../assets/adv2.JPG';
import adv3 from '../assets/adv3.JPG';
import { motion } from "framer-motion";
export default function OurProduction() {
  return (
    <div className="our-production bg-no-repeat bg-cover bg-center overflow-hidden h-[calc(100vh+300px)]">
      <div className="our-production-inner px-5 min-[410px]:px-10 sm:px-[80px] md:px-[150px] xl:px-[200px] 2xl:px-[350px] py-10 sm:py-[120px] 2xl:py-[180px] w-full h-full overflow-auto">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ ease: "easeOut", duration: 1.5, delay: 0.6 }}
          className="bg-black p-3 sm:p-5 text-2xl sm:text-3xl xl:text-4xl leading-9 xl:leading-10 mx-auto"
        >
          От изысканных натуральных тканей до современных синтетических
          трикотажных полотен — Vasat предлагает широкий ассортимент
          высококачественных трикотажных изделий. Мы сочетаем древние традиции
          ткачества с передовыми технологиями, чтобы создавать трикотаж, который
          не только красив, но и долговечен. Откройте для себя наш полный
          ассортимент и последние коллекции в{" "}
          <a className="underline decoration-1 underline-offset-4" href="/">
            Vasat.com
          </a>{" "}
          . Не упустите возможность вдохновиться новыми идеями!

          <div className="flex items-center justify-center gap-x-2 sm:gap-x-6 lg:gap-x-12 my-4 lg:my-6">
            <img src={adv2} alt='production' className='w-[180px] sm:w-[250px] lg:w-[250px] h-[180px] sm:h-[200px] lg:h-[250px] object-cover' />
            <img src={adv3} alt='production' className='w-[180px] sm:w-[250px] lg:w-[250px] h-[180px] sm:h-[200px] lg:h-[250px] object-cover'/>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
