import Header from "./views/Header";
import Hero from "./views/Hero";
import About from "./views/AboutSection";
import OurProduction from "./views/OurProduction";
import OurAdvantages from "./views/OurAdvantages";
import OurWork from "./views/OurWork";
import Footer from "./views/Footer";
import Contact from "./views/Contact";
import Form from "./views/Form";
import { useEffect, useState } from "react";

function App() {
  const [isShow, setIsShow] = useState(false);
  return (
    <div className="w-full h-full overflow-y-scroll overflow-x-hidden">
      <div className="h-[150px] sm:h-[200px]"></div>
      <Header setIsShow={setIsShow}/>
      <Hero  setIsShow={setIsShow}/>
      <About setIsShow={setIsShow}/>
      <OurProduction /> 
      <OurAdvantages/>
      <OurWork setIsShow={setIsShow}/>
      {isShow ? <Form  setIsShow={setIsShow}/> : undefined}
      <Contact/>
      <Footer setIsShow={setIsShow}/>
    </div>
  );
}

export default App;